/* global googletag, gptadslots */
import { dfpManager } from '../../../base/js/dfp/dfp-manager';


googletag.cmd.push(() => {
  // Full-page banner
  const mapping1 = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50]])
    .build();

  // The above-the-header banner (LB1)
  const mappingHeader = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([0, 0], [])
    .build();

  // For 728x90 - LB2,LB3
  const mapping2 = googletag.sizeMapping()
    .addSize([1220, 0], [728, 90])
    .addSize([1024, 0], [])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50], [320, 100]])
    .build();

  // For 160x600 - WSKY1
  const mapping3 = googletag.sizeMapping()
    .addSize([1024, 0], [[160, 600], [300, 600]])
    .addSize([0, 0], [[336, 280], [300, 250]])
    .build();

  // For 300x250 - MPU4
  const mapping6 = googletag.sizeMapping()
    .addSize([1024, 0], [])
    .addSize([0, 0], [300, 250])
    .build();

  // Adslot 1 declaration
  gptadslots[1] = googletag.defineSlot(
    '/55877742/EMS1',
    [[728, 90]],
    'div-gpt-ad-718958762716565233-1',
  ).setTargeting('pos', ['LB1']).defineSizeMapping(mappingHeader)
    .addService(googletag.pubads());

  // Adslot 4 declaration
  gptadslots[4] = googletag.defineSlot(
    '/55877742/EMS1',
    [[160, 600]],
    'div-gpt-ad-718958762716565233-4',
  ).setTargeting('pos', ['WSKY1']).defineSizeMapping(mapping3)
    .addService(googletag.pubads());

  // Adslot 9 declaration
  gptadslots[9] = googletag.defineSlot(
    '/55877742/EMS1',
    [[300, 90]],
    'div-gpt-ad-718958762716565233-9',
  ).setTargeting('pos', ['30090-1']).addService(googletag.pubads());

  // Adslot 14 declaration
  gptadslots[14] = googletag.defineSlot(
    '/55877742/EMS1',
    [[300, 90]],
    'div-gpt-ad-718958762716565233-14',
  ).setTargeting('pos', ['30090-2']).addService(googletag.pubads());

  // Adslot 15 declaration
  gptadslots[15] = googletag.defineSlot(
    '/55877742/EMS1',
    [[300, 90]],
    'div-gpt-ad-718958762716565233-15',
  ).setTargeting('pos', ['30090-3']).addService(googletag.pubads());

  // Adslot 10 declaration
  gptadslots[10] = googletag.defineSlot(
    '/55877742/EMS1_Skin',
    [[1, 1]],
    'div-gpt-ad-718958762716565233-10',
  ).addService(googletag.pubads());

  // A regular LB2 - a 728px ad in the content area
  gptadslots[13] = googletag.defineSlot(
    '/55877742/EMS1',
    [[728, 90]],
    'div-gpt-ad-718958762716565233-13',
  ).setTargeting('pos', ['LB2']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // Adslot 17 declaration
  gptadslots[17] = googletag.defineSlot(
    '/55877742/EMS1',
    [[300, 250]],
    'div-gpt-ad-718958762716565233-17',
  ).setTargeting('pos', ['MPU2']).addService(googletag.pubads());

  // Adslot 18 declaration
  gptadslots[18] = googletag.defineSlot(
    '/55877742/EMS1',
    [[728, 90]],
    'div-gpt-ad-718958762716565233-18',
  ).setTargeting('pos', ['LB3']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // Adslot 19 declaration
  gptadslots[19] = googletag.defineSlot(
    '/55877742/EMS1',
    [[300, 250]],
    'div-gpt-ad-718958762716565233-19',
  ).setTargeting('pos', ['MPU3']).addService(googletag.pubads());

  // Adslot 20 declaration
  gptadslots[20] = googletag.defineSlot(
    '/55877742/EMS1',
    [[300, 250]],
    'div-gpt-ad-718958762716565233-20',
  ).setTargeting('pos', ['MPU4']).defineSizeMapping(mapping6)
    .addService(googletag.pubads());

  // Adslot 21 declaration - Out of Page
  gptadslots[21] = googletag.defineOutOfPageSlot(
    '/55877742/EMS1_Adhesion',
    'div-gpt-ad-718958762716565233-21',
  ).addService(googletag.pubads());

  gptadslots[22] = googletag.defineSlot(
    '/55877742/EMS1_Sponsored_Article',
    [[728, 90]],
    'div-gpt-ad-718958762716565233-22',
  ).setTargeting('pos', ['SponsoredLB']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // A sticky LB2, it spans both the content and the sidebar
  gptadslots[29] = googletag.defineSlot(
    '/55877742/EMS1',
    [[728, 90]],
    'div-gpt-ad-718958762716565233-29',
  ).setTargeting('pos', ['LB2']).defineSizeMapping(mapping1)
    .addService(googletag.pubads());

  dfpManager.setTargets(googletag);
  dfpManager.enable();
});
